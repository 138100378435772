import { FooterLogo, Facebook, Insta, Youtube, FooterSvg } from "../../Constants/Svgs";
import Button from "../../components/ui/Button";
import footerPattern from "../../assets/FooterSvg.svg";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { adminPath } from "../../services/defaultSettings";
import FooterLink from "./components/FooterLink";
import { Icon } from "@iconify/react/dist/iconify.js";
const NewFooter = () => {
    const location = useLocation();

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (location.pathname.includes(adminPath)) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);

    return (
        <div className={`w-full flex-center-both ${className}`}>
            <div className="footer py-10 bg-secBlue-600 flex-center-both flex-col gap-10 mx-5 mb-4 rounded-[44px] space-y-6 relative w-full overflow-hidden">
                <div className="absolute inset-0 overflow-hidden">
                    {/* <ReactSVG src={FooterSvg} className="w-full h-full object-cover" /> */}
                    <img src={footerPattern} alt="FooterPattern" className="w-full object-cover " />
                </div>
                <div className="relative z-10 flex justify-between items-center flex-col-reverse md:flex-row gap-14">
                    <div className="flex-1 flex flex-col gap-10">
                        <div className="flex flex-1 flex-col gap-7 items-center justify-center">
                            <img src={FooterLogo} className="w-[130px]" alt="" />
                            <p className="text-center font-greta text-2xl sm:text-4xl   text-white">
                                كُتبك و روقانــك عندنا
                            </p>
                        </div>
                    </div>
                </div>
                <div className="relative z-10 flex flex-col flex-center-both space-y-5">
                    <span className="font-greta text-2xl text-moGreen-700">تابعنا دلوقتــــي:</span>
                    <div className="flex gap-10 justify-center w-full">
                        <a href="https://www.facebook.com/bassthalkedu">
                            <img src={Facebook} alt="" />
                        </a>
                        <a href="https://www.instagram.com/bassthalkedu">
                            <img src={Insta} alt="" />
                        </a>
                        <a href="https://www.youtube.com/user/mohamed17779">
                            <img src={Youtube} alt="" />
                        </a>
                    </div>
                </div>
                {/* <div className="Footer__wrapper-button relative z-10 mt-9 lg:mt-0">
                    <div className="en textx text-center font-com space-x-2 opacity-90 px-5 flex flex-wrap flex-center-both">
                        <span className="font-w-bold space-x-1">
                            <span className="text-goBlue-950">&#60;</span>
                            <span className="text-goBlue-800">Developed By</span>
                            <span className="text-goBlue-950">&#62;</span>
                        </span>
                        <span>
                            <a
                                href="https://www.facebook.com/Om4rS4Ieh/"
                                className="text-moGreen-500 font-w-bold  hover-shadow smooth px-2 py-2 rounded-md"
                            >
                                Omar
                            </a>
                            <span>,</span>
                            <a
                                href="https://www.facebook.com/emad.sharf.16"
                                className="text-moGreen-500 font-bold hover-shadow smooth px-3 py-1 rounded-md"
                            >
                                Emad
                            </a>
                        </span>
                        <span className="font-w-bold space-x-1">
                            <span className="text-goBlue-950">&#60;</span>
                            <span className="text-goBlue-900">
                                All Copy Rights Reserved @{new Date().getFullYear()}
                            </span>
                            <span className="text-goBlue-950">&#62;</span>
                        </span>
                    </div>
                </div> */}
                <div className="fixed bottom-5 left-5 text-white z-50">
                    <FooterLink
                        link="https://t.me/+201103391733"
                        className={`bg-gradient-to-r from-cyan-500 to-blue-500 `}
                        icon={<Icon icon="mingcute:telegram-fill" />}
                    />
                </div>
            </div>
        </div>
    );
};

export default NewFooter;
